import React, { useState,useEffect  } from "react";
import '../assets/css/dashlite.css';
import '../assets/css/theme.css';
import '../assets/css/Custom.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { AuthToken, BASE_URL } from "../Constants";
import { Backdrop, CircularProgress } from '@mui/material';

function Login (){    
    const[Rdata, setRdata] = useState("noloader");
    const [Mobile, setMobile] = useState("");
    const[userErr,setUserErr]=useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [message, setMessage] = useState("");
    

    const [posts, setPosts] = useState("");

    localStorage.setItem('Mobile',Mobile);

    console.log(Rdata);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if(Mobile != "" && Mobile.length == 10)
        {
            setRdata("showloader");
            const response = await fetch(BASE_URL+"/Account/SendOTP", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ Mobile,AuthToken }),
            });
            
            // Check the status of the response
            if (response.status === 200) {
                setRdata("noloader");
                navigate('/verify-otp'); 
            } else {
                setRdata("noloader");
                console.error("Failed to send OTP");
            }
        }        
    }
        

    function showToastMessage(){
        if(Mobile == "")
        {
            toast.error("Mobile number is required");
        }        
        else if(Mobile.length<10)
        {
            toast.error("Mobile number entered is not 10 digits");
        }
    }


    function mobileHandler(e){
        let item=e.target.value;
        setMobile(item)
    }
 
    function maxLengthCheck (object){
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
        }


    return (
       
<form onSubmit={handleSubmit} className="App-logo"> 
<div className="nk-app-root">
    <div className="nk-main">
        <div className="nk-wrap nk-wrap-nosidebar">
                <div className="nk-content ">
                    <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                        
                        <div className="card card-bordered">
                            <div className="card-inner card-inner-lg">

                                <div className="nk-block-head">
                                    <div className="nk-block-head-content" style={{textAlign:"center"}}>
                                        <h4 style={{fontSize:"20px"}} className="nk-block-title">Mock Test Portal</h4>
                                    </div>
                                </div>

                                <div className="brand-logo pb-4 text-center">
                                    <img width={80} className="logo" src="Images/srlogo.png" alt="logo" />
                                </div>

                            
                                 <div className="nk-block-head">
                                    <div className="nk-block-head-content" style={{textAlign:"center"}}>
                                        <h4 className="nk-block-title">Student Login</h4>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="form-control-wrap">

                                    <input className="form-control form-control-lg" 
                                    type="number" 
                                    value={Mobile}
                                    onChange={(event) => setMobile(event.target.value)} 
                                    name="Mobile" 
                                    placeholder="Mobile Number" 
                                    maxLength={"10"} 
                                    onInput={maxLengthCheck} style={{textAlign:"center"}} 
                                    />


                                    {userErr?                                   
                                    <span style={{backgroundColor:"white",color:"red"}}>{message}</span>
                                    :""}
                                    </div>
                                </div>

                                <div className="form-group">
                                        <button onClick={showToastMessage} className="btn btn-lg btn-primary btn-block">Get OTP</button>
                                        <ToastContainer />
                                    </div>
                               
                                    {Rdata == "showloader"?
                                    <Backdrop   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}  open>
                                    <CircularProgress color="inherit" />
                                </Backdrop>    :""
                                }

                            </div>
                        </div>
                    </div>
                    
                </div>
               
            </div>
        </div>
    </div>

    </form>
    );
};

export default Login;