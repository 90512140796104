import {React, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from "react-router-dom";



function Navbars() {
    const navigate = useNavigate();
    const StudentName=localStorage.getItem('StudentName');
    
    useEffect(() => {
        if(StudentName == null){
            console.log("Checklogin");
            navigate('/');
        }
    });

    function handleLogoutClick() {
        localStorage.clear();        
        navigate('/');
      }

  return (
    <>  
    <Navbar sticky="top" style={{backgroundColor:"white",height:"60px",marginTop:"0px",marginLeft:"-13px",marginRight:"-14px",marginBottom:"20px"}}>
       <header style={{width:"100%"}}>
        
      <nav style={{display:"flex"}}>

        <div className='col-md-10' style={{float:"left"}}>
        <img
              src='Images/srlogo.png'
              width="55"
              height="55"
              marginTop="14px"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
              style={{backgroundColor:"white"}}
              
            />
        </div>
        <div className='col-md-2' style={{textAlign:"right"}}>
        <span style={{marginTop:"15px"}}>Hello {StudentName}</span><br/>
        <a href='javascript:void(0);' onClick={handleLogoutClick}>Logout</a>
        </div>
      </nav>
      
    </header>
    </Navbar>
    </>
  );
}

export default Navbars;