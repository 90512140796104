import React, { useState,useEffect  } from "react";
import '../assets/css/dashlite.css';
import '../assets/css/theme.css';
import '../assets/css/Custom.css';
import { useNavigate } from "react-router-dom";
import { AuthToken, BASE_URL } from "../Constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';

function VerifyOTP(){
    const[Rdata, setRdata] = useState("noloader");
    const navigate = useNavigate();
    const [OTP1, setOtp1] = useState("");
    const [OTP2, setOtp2] = useState("");
    const [OTP3, setOtp3] = useState("");
    const [OTP4, setOtp4] = useState("");
   
    const [otpCode, setOtpCode] = useState("");
    const [seconds, setSeconds] = useState(60);


    const Mobile=localStorage.getItem('Mobile');
       

    useEffect(() => {
      if (seconds > 0) {
        const timeoutId = setTimeout(() => {
          setSeconds(seconds - 1);
        }, 1000);
        return () => clearTimeout(timeoutId);
      }
    }, [seconds]);


   function HandleValuChange1(ot1)
   {
      setOtp1(ot1);
      document.getElementById("OTP2").focus();
   }

   function HandleValuChange2(ot1)
   {
      setOtp2(ot1);
      document.getElementById("OTP3").focus();
   }

   function HandleValuChange3(ot1)
   {
      setOtp3(ot1);
      document.getElementById("OTP4").focus();
   }
  
    function maxLengthCheck (object){
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
        }

        const handleSubmit = async (event) => {
          event.preventDefault();
          const OTP = OTP1+OTP2+OTP3+OTP4;

          if(OTP == "")
          {
              toast.error("Please enter OTP");
          }

          if(OTP != ""){
            setRdata("showloader");
          // make an API call to verify the OTP entered by the user
          
          

          fetch(BASE_URL+"/Account/VerifyOTP", {
            method: "POST",
            body: JSON.stringify({ OTP,AuthToken,Mobile }),
            headers: {
              "Content-Type": "application/json",
            },
           })
            .then((response) => response.json())
            .then((data) => {
              if (data.IsCallSuccessful) {
                if(data.IsOTPVerified == "Y")
                {
                    localStorage.setItem('StudentName',data.StudentName);
                    localStorage.setItem('EmailID',data.EmailID);
                    localStorage.setItem('StudentID',data.StudentID);
                    if(data.StudentName == "")
                    {
                        //Navigate to update detail page                        
                        navigate('/student-details');
                    }
                    else{
                        //Navigate to test instruction page   
                        if(data.TestAttemptCount > 0)
                        {
                          navigate('/test-result');
                        }      
                        else{               
                        navigate('/instruction');
                        }
                    }
                }
                else{
                  setRdata("noloader");
                  toast.error(data.ErrorMessage);
                }
              } else {
                setRdata("noloader");
                toast.error(data.ErrorMessage);
              }
            });
          }
        };



        const handleresendOTP = async (event) => {
          event.preventDefault();
          
         
          
              setRdata("showloader");
              const response = await fetch(BASE_URL+"/Account/SendOTP", {
                  method: "POST",
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ Mobile,AuthToken }),
              });
              
              // Check the status of the response
               
              if (response.status === 200) {
                  setRdata("noloader");
                  toast.success("otp sent succesfully");
              } 
             
              else {
                  setRdata("noloader");
                  toast.error("Failed to send OTP")
              }          
      }

    
    const NavigateLogin = async (event) => {
        event.preventDefault(); 
        navigate('/');    
      }

    
    return (
        <form onSubmit={handleSubmit} className="App-logo"> 
        <div className="nk-app-root">
            <div className="nk-main">
                <div className="nk-wrap nk-wrap-nosidebar">
                        <div className="nk-content ">
                            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                               
                                <div className="card card-bordered">    
                                    <div className="card-inner card-inner-lg">

                                    <div className="nk-block-head">
                                        <div className="nk-block-head-content" style={{textAlign:"center"}}>
                                            <h4 style={{fontSize:"20px"}} className="nk-block-title">Mock Test Portal</h4>
                                        </div>
                                    </div>

                                    <div className="brand-logo pb-4 text-center">
                                        <img width={80} className="logo" src="Images/srlogo.png" alt="logo" />
                                    </div>
                                        <div className="nk-block-head">
                                            <div className="nk-block-head-content" style={{textAlign:"center"}}>
                                                <h4 className="nk-block-title">Verify OTP</h4>
                                            </div>
                                        </div>
        
                                        <div className="form-group">
                                            <div className="form-control-wrap">
        
                                            <input autoComplete="off" className="m-2 text-center otp-box rounded" name="OTP1" type="number" id="OTP1" value={OTP1} onChange={(e) => HandleValuChange1(e.target.value)} maxLength="1" onInput={maxLengthCheck} /> 
                                            <input autoComplete="off" className="m-2 text-center otp-box rounded" name="OTP2" type="number" id="OTP2" value={OTP2} onChange={(e) => HandleValuChange2(e.target.value)} maxLength="1" onInput={maxLengthCheck} /> 
                                            <input autoComplete="off" className="m-2 text-center otp-box rounded" name="OTP3" type="number" id="OTP3" value={OTP3} onChange={(e) => HandleValuChange3(e.target.value)} maxLength="1" onInput={maxLengthCheck} /> 
                                            <input autoComplete="off" className="m-2 text-center otp-box rounded" name="OTP4" type="number" id="OTP4" value={OTP4} onChange={(e) => setOtp4(e.target.value)} maxLength="1" onInput={maxLengthCheck} />
        
        
                                            </div>
                                        </div>
                                        <div style={{clear:"both"}}></div>
                                        <div className="form-group">
                                                <button className="btn btn-lg btn-primary btn-block">Verify OTP</button>
                                                <ToastContainer />
                                            </div>

                                            {Rdata == "showloader"?
                                                <Backdrop   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}  open>
                                                <CircularProgress color="inherit" />
                                            </Backdrop>    :""
                                            }
                                       
                                            {seconds === 0 ? 
                                                (
                                                    <div className="text-center">
                                                <a style={{cursor:"pointer", color:"blue"}} onClick={handleresendOTP} className="bttnn-csss">Resend OTP </a>
                                                </div>
                                                ) 
                                                : (
                                                <p className="font-weight-bold text-danger cursor" style={{marginLeft:"60px",backgroundColor:"white"}}>Resend OTP in <small className="time-css">{seconds}</small> seconds</p>
                                                )}


                                                <div>
                                                <img
                                                  src='Images/BackArrow.png'
                                                  width="25"
                                                  height="25"
                                                  marginTop="14px"
                                                  textAlign="center"
                                                  className="d-inline-block align-top"
                                                  alt="React Bootstrap logo"
                                                  style={{backgroundColor:"white",textAlign:"center",marginLeft:"120px", cursor:"pointer"}}
                                                  onClick={NavigateLogin}
                                                  
                                                />
                                                </div>

                                                
                                            
        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                       
                    </div>
                </div>
            </div>
        
            </form>
            );
}

export default VerifyOTP;