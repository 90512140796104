// import React from "react";
import React, { useState,useEffect  } from "react";
import Navbar from './Navbar';
import { Card } from "react-bootstrap";
import '../assets/css/dashlite.css';
import '../assets/css/theme.css';
import '../assets/css/Custom.css';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';
import { AuthToken, BASE_URL } from "../Constants";

const Instruction = ()=>{
    const[Rdata, setRdata] = useState("noloader");
    const[MockTestStart, setMockTestStart] = useState();


//     document.addEventListener('contextmenu', (e) => e.preventDefault());

// function ctrlShiftKey(e, keyCode) {
//   return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
// }

// document.onkeydown = (e) => {
//   // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
//   if (
//     e.keyCode === 123 ||
//     ctrlShiftKey(e, 'I') ||
//     ctrlShiftKey(e, 'J') ||
//     ctrlShiftKey(e, 'C') ||
//     (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
//   )
//     return false;
// };

    // const StudentName=localStorage.getItem('StudentName');


    const navigate = useNavigate();
    const NavigateTest = async (event) => {
        event.preventDefault(); 
        navigate('/mock-test');    
      }


      useEffect(() => {       
        
        setRdata("showloader");
        fetch(BASE_URL+"/mocktest/get-mock-test-status", {
            method: "POST",
            body: JSON.stringify({ AuthToken}),
            headers: {
              "Content-Type": "application/json",
            },
           })
            .then((response) => response.json())
            .then((data) => {
              if (data.IsCallSuccessful) {  
                setRdata("noloader");              
                setMockTestStart(data.TestStatus);
                }    
                else {
                    setRdata("noloader");
                    toast.error(data.ErrorMessage);
                  }
              } 
              
            );
          
    },[]);


    const Instructions = [];

    if(MockTestStart === "Y")
    {
        Instructions.push(<div>
                                                
                                            
            <h3 style={{marginTop:"21px"}}>ALL INDIA UPPSC GS MOCK TEST - (Hindi) (11 August, 02:00 PM to 06:00 PM)</h3>
            <h4 style={{marginBottom:"28px"}}>Instructions</h4>
            <p className="pp">1) The test contains 150 Questions.</p>
            <p className="pp">2) Each Question has 4 options out of which only one is correct.</p>
            <p className="pp">3) You have to finish the test in 120 minutes.</p>
            <p className="pp">4) You will be awarded 1.33 marks for each correct answer and <b>-0.44</b> for negative marking.</p>
            <p className="pp">5) There is no penality for the questions that you have not attempted.</p>
            <p className="pp">6) Once you start the test, you will not be allowed to reattempt it. Make sure that you complete before you submit the test and/or close the browser.</p>
            <p className="pp">7) If browser is closed during the test, <b>Test</b> attempt will be lost.</p>
            <p className="pp">8) Clicking Refresh is not allowed and Test will be submitted if Refresh is clicked and the attempt will be lost</p>
            <p className="pp">9) Clicking Back button is not allowed.</p>
            <p className="pp">10) Student cannot give the Test again if internet connection is lost due to whatsoever reason after the Test is started. Result of the Test will be "Nil" in this case.</p>
            <p>
            Declaration:I have read all the instructions carefully and have understood them. <br/>I agree not to cheat or use unfair means in the examination. <br/>I understand the using unfair means of any sort for my own or someone else's advantage will lead to immediate disqualification.
            </p >
            
            <br/>
            <center>
            <button onClick={NavigateTest} className="btn btn-primary" style={{marginBottom:"15px"}} >Start Test</button></center>

        </div>);
    }
    else{
        Instructions.push(
            <div>
                                                
                                            
                                            <h3 style={{marginTop:"21px"}}>ALL INDIA UPPSC GS MOCK TEST - (Hindi) (11 August, 02:00 PM to 06:00 PM)</h3>
                                            <h4 style={{marginBottom:"28px"}}>Instructions</h4>
                                            
                                            <p className="pp" style={{color:"red"}}>Test will start on 11 August at 02:00 PM</p>
                                            <p className="pp">&nbsp;</p>
                                        </div>
        );
    }

    return(
        <>
        <Navbar/>
        
        <div >   
             
        <form> 
        <div className="nk-app-root">
            <div className="nk-main">
                <div className="nk-wrap nk-wrap-nosidebar">
                        <div className="nk-content ">
                            <div className="col-md-12">
                                 
                                <div className="card card-bordered">    
                                    <div className="col-md-12">
                                         
                                            {Instructions}
                      
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                       
                    </div>
                </div>
            </div>
        
            </form>


            {Rdata === "showloader"?
                <Backdrop   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}  open>
                <CircularProgress color="inherit" />
            </Backdrop>    :""
            }


        </div >

        </>
    )
}

export default Instruction