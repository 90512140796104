import React, { useState,useEffect  } from "react";
import '../assets/css/dashlite.css';
import '../assets/css/theme.css';
import '../assets/css/Custom.css';
import { useNavigate } from "react-router-dom";
import { AuthToken, BASE_URL } from "../Constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';

function StudentDetails(){
    const[Rdata, setRdata] = useState("noloader");
    const navigate = useNavigate();
    const [EmailID, setEmailID] = useState("");
    const[FirstName,setFirstName]=useState("");
    const[LastName,setLastName]=useState("");
    const [StudentName, setStudentName] = useState("");
    const Mobile=localStorage.getItem('Mobile');
   

    const handleUpdateDetails = async (event) => {
        event.preventDefault();
        
        const fullName = `${FirstName} ${LastName}`;
        console.log(fullName)
        setStudentName(fullName);
  
        if(FirstName == "")
        {
            toast.error("First name is required");
        }
        if(LastName == "")
        {
            toast.error("Last name is required");
        }
        if(EmailID == "")
        {
            toast.error("Email address is required");
        }
        else if(!isValid){
            toast.error('Please use valid gmail address');
        }

        if(StudentName != "" && EmailID != "" && isValid){
            setRdata("showloader");
        fetch(BASE_URL+"/Account/UpdateStudentDetails", {
            method: "POST",
            body: JSON.stringify({ StudentName, AuthToken, Mobile, EmailID }),
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => response.json())
          .then((data) => {
            if (data.IsCallSuccessful) {
                localStorage.setItem('StudentName',StudentName);
                localStorage.setItem('EmailID',EmailID);
                localStorage.setItem('StudentID',data.StudentID);
                navigate('/instruction');
            }
            else{
                setRdata("noloader");
                toast.error(data.ErrorMessage);
            }
          });
        }

        
       
    }
    const [isValid, setIsValid] = useState(false);

    const handleEmailChange = event => {
        const { value } = event.target;
        setEmailID(value);
        setIsValid(/^[^\s@]+@gmail\.com$/.test(value));
      };

    return (
        <form className="App-logo"> 
        <div className="nk-app-root">
            <div className="nk-main">
                <div className="nk-wrap nk-wrap-nosidebar">
                        <div className="nk-content ">
                            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                               
                                <div className="card card-bordered">    
                                    <div className="card-inner card-inner-lg">

                                    <div className="nk-block-head">
                                    <div className="nk-block-head-content" style={{textAlign:"center"}}>
                                        <h4 style={{fontSize:"20px"}} className="nk-block-title">Mock Test Portal</h4>
                                    </div>
                                   </div> 

                                    <div className="brand-logo pb-4 text-center">
                                        <img width={80} className="logo" src="Images/srlogo.png" alt="logo" />
                                    </div>
                                        <div className="nk-block-head">
                                            <div className="nk-block-head-content" style={{textAlign:"center"}}>
                                                <h4 className="nk-block-title">Student Details</h4>
                                            </div>
                                        </div>
        
                                        {/* <div className="form-group">
                                            <div className="form-control-wrap">        
                                                <input placeholder="Student Name" required className="form-control rounded" name="StudentName" type="text" id="StudentName" value={StudentName} onChange={(e) => setStudentName(e.target.value)} maxLength="100" />                                                 
                                                
                                            </div>                                           
                                        </div> */}

                                        <div className="form-group">
                                            <div className="form-control-wrap">   
                                                 <input placeholder="* First Name" required className="form-control rounded" name="FirstName" type="text" id="FirstName"value={FirstName} onChange={(e) => setFirstName(e.target.value)} maxLength="100"  />                                              
                                                
                                            </div>                                           
                                        </div>

                                        <div className="form-group">
                                            <div className="form-control-wrap">        
                                                <input placeholder ="* Last Name" required className="form-control rounded" name="LastName" type="text" id="LastName" value={LastName} onChange={(e) => setLastName(e.target.value)}  maxLength="100"/>                                                 
                                                
                                            </div>                                           
                                        </div>


                                        <div className="form-group">
                                            <div className="form-control-wrap">
                                            <input type="text" placeholder="* Email Address" required  className="form-control rounded" name="EmailID" id="EmailID" value={EmailID} onChange={handleEmailChange} />

                                                {/* <input placeholder="Email Address" required  className="form-control rounded" name="EmailID" type="text" id="EmailID" value={EmailID} onChange={(e) => setEmailID(e.target.value)} maxLength="100" />  */}
                                               
                                            </div>
                                            {/* {isValid ? <p>Email is valid</p> : <p>Email is invalid</p>} */}
                                        </div>
                                        <div style={{clear:"both"}}></div>
                                        <div className="form-group">
                                                <button onClick={handleUpdateDetails} className="btn btn-lg btn-primary btn-block">Update Details & Start Test</button>
                                                <ToastContainer />
                                            </div>
                                            
                                            {Rdata == "showloader"?
                                                <Backdrop   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}  open>
                                                <CircularProgress color="inherit" />
                                            </Backdrop>    :""
                                            }
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                       
                    </div>
                </div>
            </div>
        
            </form>
            );
}

export default StudentDetails;