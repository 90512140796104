import React, { useState,useEffect  } from "react";
import Navbar from './Navbar';
import { Card } from "react-bootstrap";
import '../assets/css/dashlite.css';
import '../assets/css/theme.css';
import '../assets/css/Custom.css';
import { AuthToken, BASE_URL } from "../Constants";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import { zeroPad } from "react-countdown";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';


const TestResult = ()=>{
    const[Rdata, setRdata] = useState("noloader");
    const[MockTestID, setMockTestID] = useState(10);
    const[StudentID, setStudentID] = useState(localStorage.getItem("StudentID"));
    const[TotalStudents, setTotalStudents] = useState(0);
    const[TotalMarks, setTotalMarks] = useState(0);
    const[Percentile, setPercentile] = useState(0);
    const[TotalQuestions, setTotalQuestions] = useState(0);
    const[CorrectQuestions, setCorrectQuestions] = useState(0);
    const[WrongQuestions, setWrongQuestions] = useState(0);
    const[SkippedQuestions, setSkippedQuestions] = useState(0);
    const[SubmitDate, setSubmitDate] = useState(0);
    
    const EmailID=localStorage.getItem('EmailID');
    const navigate = useNavigate();
    
    //Disable Back Button//
    var ctrlKeyDown = false;
    useEffect(() => {
        const handleBackButton = (e) => {
          e.preventDefault();
          navigate('/mock-test');
        };
    
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handleBackButton);
    
        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
      }, []);

    //Disable Refresh and ctrl R//


    function my_onkeydown_handler( e ) {
        switch (e.keyCode) {
            case 116 : // 'F5'
                e.preventDefault();
                e.keyCode = 0;
                window.status = "F5 disabled";
                break;
        }

        if ((e.which || e.keyCode) == 116 || ((e.which || e.keyCode) == 82 && ctrlKeyDown)) {
            // Pressing F5 or Ctrl+R
            e.preventDefault();
        } else if ((e.which || e.keyCode) == 17) {
            // Pressing  only Ctrl
            ctrlKeyDown = true;
        }
    }
    document.addEventListener("keydown", my_onkeydown_handler);

    document.onkeydown = function(event){
        alert(event.keyCode);
        switch (event.keyCode){            
              case 116 : //F5 button
                  event.returnValue = false;
                  event.keyCode = 0;
                  return false;
              case 82 : //R button
                  if (event.ctrlKey){ 
                      event.returnValue = false;
                      event.keyCode = 0;
                      return false;
                  }
          }
      }
      //window.onbeforeunload = function () {navigate('/instruction');}
      window.onbeforeunload = ()=>"If you leave this page, you'll also leave the call";
    
    //


   
    //Disable Back Button//
    document.addEventListener('contextmenu', (e) => e.preventDefault());

        function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
        }

        document.onkeydown = (e) => {
        // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        if (
            e.keyCode === 123 ||
            ctrlShiftKey(e, 'I') ||
            ctrlShiftKey(e, 'J') ||
            ctrlShiftKey(e, 'C') ||
            (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
        )
            return false;
        };
    useEffect(() => {
        const handleBackButton = (e) => {
          e.preventDefault();
          navigate('/mock-test');
        };
    
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handleBackButton);
    
        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
      }, []);

      

    //Disable Back Button//
    function hndlePageLoad() {
        alert("Page loads");
    }
    useEffect(() => {       
        
        setRdata("showloader");
        fetch(BASE_URL+"/mocktest/get-mock-test-result", {
            method: "POST",
            body: JSON.stringify({ MockTestID,AuthToken, StudentID}),
            headers: {
              "Content-Type": "application/json",
            },
           })
            .then((response) => response.json())
            .then((data) => {
              if (data.IsCallSuccessful) {    
                setRdata("noloader");            
                setTotalStudents(data.TotalStudents);
                setTotalMarks(data.TotalMarks);
                setPercentile(data.Percentile);
                setTotalQuestions(data.TotalQuestions);
                setWrongQuestions(data.WrongQuestions);
                setSkippedQuestions(data.SkippedQuestions);
                setCorrectQuestions(data.CorrectQuestions);
                setSubmitDate(data.SubmitDate);
                }    
                else {
                    setRdata("noloader");
                    toast.error(data.ErrorMessage);
                  }
              } 
              
            );
          
    },[]);

    
    
        return(
            <>
            <Navbar/>
            
            <div >   
                 
            <form> 
            <div className="nk-app-root">
                <div className="nk-main">
                    <div className="nk-wrap nk-wrap-nosidebar">
                            <div className="nk-content ">
                                <div className="col-md-12">
                                     
                                    <div className="card card-bordered">    
                                        <div className="col-md-12">
                                             
                                                <div>                                                    
                                                
                                                <h3 style={{marginTop:"21px"}}>ALL INDIA UPPSC GS MOCK TEST - (Hindi) (11 August, 02:00 PM to 06:00 PM)</h3>
                                                <h4 style={{marginBottom:"28px"}}>Result Summary</h4>
                                                <p className="pp">
                                                    
                                                <table style={{width:"50%", marginTop:"30px"}}>
                                                    
                                                    <tr>
                                                        <td>1</td>
                                                        <td><b>Total Marks</b></td>
                                                        <td>{TotalMarks}</td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td>2</td>
                                                        <td><b>Total Questions</b></td>
                                                        <td>{TotalQuestions}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td><b>Correct Questions</b></td>
                                                        <td>{CorrectQuestions}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td><b>Wrong Questions</b></td>
                                                        <td>{WrongQuestions}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td><b>Skipped Questions</b></td>
                                                        <td>{SkippedQuestions}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td><b>Attempted On</b></td>
                                                        <td>{SubmitDate}</td>
                                                    </tr>
                                                    </table>

                                                </p>
                                                <br/>
                                                <div style={{paddingBottom:"20px"}}>
                                                <span style={{color:"red"}}>Note: Final ranking will be emailed to you on <span>{EmailID}</span></span>
                                                </div>
                                            </div>
                          
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                           
                        </div>
                    </div>
                </div>
            
                </form>
    
             
    
                {Rdata === "showloader"?
                <Backdrop   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}  open>
                <CircularProgress color="inherit" />
            </Backdrop>    :""
            }
    
            </div >
    
            </>
        )
    }
    
    export default TestResult